import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useCallback, useState } from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import EditIcon from '@mui/icons-material/EditOutlined';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Tooltip } from '@mui/material';
import { getAddressTitle, getBrandName, isResidential, writeToClipboard, } from '../../utils';
import { DzSidePanelHeader } from '../shared/dz-side-panel-header';
import { useStyles } from './dz-address-panel-header.styles';
import { DzContentCopyIcon, DzZendeskIcon } from '../icons';
import { DzSidebarHeaderPlaceholder, useModalManager, useTimer, useComplimentaryPremiumPeriod, useUserConfig, usePartnerConfig, } from '../shared';
import { ModalIDs } from '../../const';
import { AddHome } from '@mui/icons-material';
const EDIT_ADDRESS = 'Edit Address';
const OPEN_ZENDESK = 'Open in Zendesk';
const COPY_ADDRESS = 'Copy Address ID';
const CPP_EXP_DATE = 'Add Complimentary Period';
const UPDATE_SERVICE_LEVEL = 'Update Service Level';
const DzProjectsPageSidePanelHeaderView = ({ address, partners, brands, actions, clients, dispatch, zendesk, apiClient, }) => {
    var _a;
    const classes = useStyles();
    const { accessSubscriptionManagement: canManageSubscription } = usePartnerConfig();
    const { user: { can_update_sla: canUpdateSLA }, } = useUserConfig();
    const [isOpenMenu, switchMenu] = useState(false);
    const zendeskUrl = (_a = partners.find((partner) => partner.partnerId === Number(address === null || address === void 0 ? void 0 : address.ovpid))) === null || _a === void 0 ? void 0 : _a.zendeskUrl;
    const { openModal } = useModalManager();
    const handleEditAddress = useCallback(() => {
        if (address) {
            dispatch(actions.openUpdateAddressDialog(address));
            switchMenu(false);
        }
    }, [address, dispatch, actions]);
    const handleUpdateServiceLevel = useCallback(() => {
        if (address) {
            dispatch(actions.openUpdateServiceLevelDialog(address));
            switchMenu(false);
        }
    }, [address, dispatch, actions]);
    const handleRedirectZen = useCallback(() => {
        if (zendeskUrl && (address === null || address === void 0 ? void 0 : address.zdOrgId)) {
            window.open(`${zendeskUrl}/organizations/${address.zdOrgId}`, '_blank');
        }
    }, [zendeskUrl, address]);
    const [isTimerRunning, startTimer] = useTimer();
    const copyAddress = useCallback(async () => {
        if (!address) {
            return;
        }
        await writeToClipboard(address.ovaid, 'text/plain');
        startTimer();
    }, [address, startTimer]);
    const handleCppExpDateDialog = useCallback(async () => {
        await openModal(ModalIDs.cppExpDate, {
            address: address,
            dispatch,
            actions,
            zendesk,
        });
        switchMenu(false);
    }, [actions, address, dispatch, openModal, zendesk]);
    const { isCppDialogOn } = useComplimentaryPremiumPeriod(address);
    const menuList = useMemo(() => {
        if (!address)
            return [];
        const list = [
            {
                name: EDIT_ADDRESS,
                icon: _jsx(EditIcon, { color: "primary", fontSize: "small" }),
                action: handleEditAddress,
            },
            {
                name: OPEN_ZENDESK,
                icon: (_jsx(DzZendeskIcon, { viewBox: "0 0 24 24", classes: { root: classes.zendeskIcon } })),
                action: handleRedirectZen,
            },
            {
                name: CPP_EXP_DATE,
                icon: _jsx(VerifiedUserIcon, { fontSize: "small" }),
                action: handleCppExpDateDialog,
                tooltip: isCppDialogOn ? '' : 'Unable to add CPP to organization.',
                disabled: !isCppDialogOn,
                hide: !canManageSubscription,
            },
            {
                name: COPY_ADDRESS,
                icon: isTimerRunning ? (_jsx(CheckIcon, { fontSize: "small" })) : (_jsx(DzContentCopyIcon, { fontSize: "small" })),
                action: copyAddress,
            },
            {
                name: UPDATE_SERVICE_LEVEL,
                icon: _jsx(AddHome, { fontSize: "small" }),
                action: handleUpdateServiceLevel,
                hide: Boolean(!canUpdateSLA || address.chargifySubId),
            },
        ].filter((el) => !el.hide);
        if (zendeskUrl && address.zdOrgId) {
            if (zendesk) {
                return list.filter((el) => el.name !== OPEN_ZENDESK);
            }
            else {
                return list;
            }
        }
        return list.filter((el) => el.name === EDIT_ADDRESS);
    }, [
        address,
        handleEditAddress,
        classes.zendeskIcon,
        handleRedirectZen,
        handleCppExpDateDialog,
        isCppDialogOn,
        canManageSubscription,
        isTimerRunning,
        copyAddress,
        handleUpdateServiceLevel,
        canUpdateSLA,
        zendeskUrl,
        zendesk,
    ]);
    const brandName = useMemo(() => getBrandName(address === null || address === void 0 ? void 0 : address.ovbid, brands), [address, brands]);
    return !address ? (_jsx(DzSidebarHeaderPlaceholder, {})) : (_jsx(DzSidePanelHeader, { icons: _jsx(Tooltip, Object.assign({ title: brandName }, { children: isResidential(address.ovbid, brands) ? (_jsx(HomeIcon, {})) : (_jsx(DomainIcon, {})) })), apiClient: apiClient, title: getAddressTitle(address), isLoading: false, menuItems: menuList, menuIsOpen: isOpenMenu, switchMenu: switchMenu, zendesk: zendesk, clients: clients, address: address }));
};
export const DzProjectsPageSidePanelHeader = React.memo(DzProjectsPageSidePanelHeaderView);
