import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DzAddress } from 'shared-ui';

export interface UpdateAddressDialogSliceState {
  isOpen: boolean;
  address: DzAddress;
  originalAddress: DzAddress;
}

export const updateAddressDialogSlice = createSlice({
  name: 'update-address-dialog',
  initialState: {
    isOpen: false,
    address: {} as Partial<DzAddress>,
  } as UpdateAddressDialogSliceState,
  reducers: {
    openUpdateAddressDialog: (_, action: PayloadAction<DzAddress>) => {
      return {
        isOpen: true,
        address: action.payload,
        originalAddress: action.payload,
      };
    },

    updateUpdateAddressDialogState: (
      state,
      action: PayloadAction<
        Partial<Omit<UpdateAddressDialogSliceState, 'originalAddress'>>
      >,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});
