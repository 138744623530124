import { useSpace } from '@flatfile/react';
import { configToBlueprint } from '@flatfile/v2-shims';
import { flatFileFields, siteTypeField } from './dz-flat-file-fields';
import FlatfileListener from '@flatfile/listener';
import api from '@flatfile/api';
import { extractDataFromFlatfileSheet, splitArray } from './format-data';
import { handleFailedRecords, uploadData } from './upload-data';
import { DzBrand } from 'shared-ui';

const spaceProps = {
  name: 'Embedded Space',
  publishableKey: process.env.REACT_APP_FLATFILE_TOKEN,
  environmentId: process.env.REACT_APP_FLATFILE_ENVIRONMENT_ID,
};

const chunkSize = parseInt(
  process.env.REACT_APP_NEW_PARTNER_DATA_UPLOAD_CHUNK_SIZE || '25',
);

// This function is called when the Submit button is hit
const listener = FlatfileListener.create((listener) => {
  listener.on(
    'job:ready',
    { job: 'workbook:submitAction' },
    async ({ context: { jobId, workbookId } }) => {
      const { data: workbookSheets } = await api.sheets.list({
        workbookId,
      });

      try {
        const extractedData = await extractDataFromFlatfileSheet(
          workbookSheets,
          api,
        );

        const dataInChunks = splitArray(extractedData, chunkSize);

        const { failedRows } = await uploadData(dataInChunks, jobId, api);

        if (failedRows.length) {
          await api.jobs.fail(jobId, {
            outcome: {
              heading: 'Import partially succeeded',
              message: 'Some records could not be imported.',
            },
          });

          await handleFailedRecords(workbookSheets[0], failedRows, api);
        } else {
          await api.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: 'The import has finished successfully.',
              next: {
                type: 'wait',
              },
            },
          });
        }
      } catch {
        await api.jobs.fail(jobId, {
          outcome: {
            message:
              'An error occurred. Please check your data and try again.',
          },
        });
      }
    },
  );
});

export const DzFlatfileSpace = ({
  brands,
  onClose,
}: {
  brands: DzBrand[];
  onClose: () => void;
}) => {
  const fields = [
    ...flatFileFields,
    {
      ...siteTypeField,
      options: brands.map((brand: DzBrand) => ({
        value: brand.ovbid,
        label: brand.name,
      })),
    },
  ];

  const flatFileConfig = {
    type: 'Partner Import',
    fields,
  };

  const space = useSpace({
    ...spaceProps,
    // Flatfile suggests adding ts-ignore for this line
    // @ts-expect-error: expected
    workbook: configToBlueprint(flatFileConfig),
    listener,
    sidebarConfig: {
      showSidebar: false,
    },
    closeSpace: {
      operation: 'submitAction',
      onClose: onClose,
    },
  });

  return space;
};
