import { Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logRender } from '@one-vision/utils';

import { useStyles } from './dz-users.styles';
import { actions, thunks } from 'core/redux';
import {
  DzNotificationSnackbar,
  DzAsyncDispatch,
  ModalIDs,
  useModalManager,
} from 'shared-ui';
import { DzUsersHeader } from './dz-users-header.view';
import { DzUsersTable, ResendInviteOptions } from './dz-users-table.view';
import { DzTemporaryPasswordDialog } from './dz-temporary-password-dialog.view';
import { useResendInvitation } from './useResendInvitation';
import { DzUpdateUserGroupsDialog } from './dz-update-user-groups-dialog.view';
import { Props as DzBulkUsersImporterProps } from './dz-bulk-users-importer';
import { API } from 'core/api';
import { DzAddOrUpdateUserDialog } from './dz-add-or-update-user-dialog.view';
import { useUsers, User } from './useUsers';
import { useUserCreateOrUpdateDialog } from './useUserCreateOrUpdateDialog';

export interface Group {
  id: string;
  name: string;
  description: string;
  isPublic: boolean;
}

const filterBySearchText = (searchText: string) => (user: User) => {
  if (!searchText) {
    return true;
  }

  return Boolean(
    Object.values(user).find((value: string) =>
      value?.toString().toLowerCase().includes(searchText.toLowerCase()),
    ),
  );
};

export const DzUsersView: React.FC = () => {
  logRender(DzUsersView);

  const classes = useStyles();

  const { allUsers, fetchUsers, updateUsersIsActiveStateById } =
    useUsers();

  const [searchText, setSearchText] = useState('');
  const users = allUsers.filter(filterBySearchText(searchText));

  const dispatch = useDispatch<DzAsyncDispatch>();
  const [userToUpdate, setUserToUpdate] = useState<User | null>(null);
  const [userToUpdateGroups, setUserToUpdateGroups] =
    useState<User | null>(null);

  const [notificationText, setNotificationText] = useState('');

  const { openModal } = useModalManager();

  const openImporter = useCallback(() => {
    openModal<DzBulkUsersImporterProps, undefined>(
      ModalIDs.BulkUsersImporter,
      {
        onFileError: setNotificationText,
      },
    );
  }, [openModal]);

  const [passwordDialogOptions, setPasswordDialogOptions] =
    useState<PasswordDialogOptions | null>(null);
  const resendInvitation = useResendInvitation(setNotificationText);
  const handleResendInvite = (options: ResendInviteOptions) => {
    if (options?.withPassword) {
      setPasswordDialogOptions({
        isOpen: true,
        userId: options.userId,
      });
      return;
    }

    resendInvitation({
      userId: options.userId,
    });
  };
  const handleResendInviteWithPassword = (password: string) => {
    setPasswordDialogOptions(null);
    resendInvitation({
      userId: passwordDialogOptions?.userId as string,
      password,
    });
  };

  const handleOpenCreateUserDialog = () => {
    setUserToUpdate({} as User);
  };

  const handleCloseCreateOrUpdateUserDialog = useCallback(() => {
    fetchUsers();
    setUserToUpdate(null);
  }, [setUserToUpdate, fetchUsers]);

  const handleUserDisable = useCallback(
    (ownerToDelete: string) => {
      if (ownerToDelete) {
        dispatch(
          thunks.deleteOwner({
            ownerId: ownerToDelete,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(
              actions.updateSnackbar({
                type: 'success',
                text: `User was deactivated`,
              }),
            );
          });
        updateUsersIsActiveStateById(ownerToDelete, false);
      }
    },
    [dispatch, updateUsersIsActiveStateById],
  );

  const { updateUser } = useUserCreateOrUpdateDialog();

  const handleUserEnable = useCallback(
    (userId: string) => {
      void updateUser(userId, { isActive: true }).then(() => {
        dispatch(
          actions.updateSnackbar({
            type: 'success',
            text: `User was activated`,
          }),
        );
      });
      updateUsersIsActiveStateById(userId, true);
    },
    [dispatch, updateUser, updateUsersIsActiveStateById],
  );

  const handleSearchTextChange = useCallback(
    (text: string) => {
      setSearchText(text);
    },
    [setSearchText],
  );

  const handleCloseNotification = useCallback(() => {
    setNotificationText('');
  }, [setNotificationText]);

  const onGroupsSubmit = useCallback(
    async (selectedGroups: Group[]) => {
      if (!userToUpdateGroups) {
        return;
      }
      try {
        await API.postUserToUserGroups({
          id: userToUpdateGroups?.ownerId,
          attributes: {
            userGroupId: selectedGroups.map((el) => el.id as string),
          },
        });
        await fetchUsers();
        dispatch(
          actions.updateSnackbar({
            type: 'success',
            text: `User permissions updated for ${userToUpdateGroups.firstName} ${userToUpdateGroups.lastName}`,
          }),
        );
        dispatch(thunks.fetchOwners());
      } catch {
        dispatch(
          actions.updateSnackbar({
            type: 'error',
            text: 'Can`t create relation',
          }),
        );
      }
    },
    [userToUpdateGroups, dispatch, fetchUsers],
  );

  return (
    <Stack className={classes.root}>
      <DzUsersHeader
        onSearchTextChange={handleSearchTextChange}
        onNewUserClick={handleOpenCreateUserDialog}
        onBulkImportClick={openImporter}
      />
      <DzUsersTable
        users={users}
        searchText={searchText}
        onDisableUser={handleUserDisable}
        onEnableUser={handleUserEnable}
        onResendInvite={handleResendInvite}
        onUserUpdate={setUserToUpdate}
        onGroupsUpdate={setUserToUpdateGroups}
      />
      <DzTemporaryPasswordDialog
        open={!!passwordDialogOptions?.isOpen}
        onClose={() => setPasswordDialogOptions(null)}
        onSend={handleResendInviteWithPassword}
      />
      <DzUpdateUserGroupsDialog
        user={userToUpdateGroups}
        onSubmit={onGroupsSubmit}
        onClose={() => setUserToUpdateGroups(null)}
      />
      <DzAddOrUpdateUserDialog
        userToUpdate={userToUpdate}
        onClose={handleCloseCreateOrUpdateUserDialog}
      />
      <DzNotificationSnackbar
        text={notificationText}
        onClose={handleCloseNotification}
      />
    </Stack>
  );
};

interface PasswordDialogOptions {
  isOpen: boolean;
  userId: string;
}
