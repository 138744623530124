import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, Tooltip, Typography, Box, FormControlLabel, Switch, Stack, } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { getChanges, csn } from '@one-vision/utils';
import { readAsList } from '@one-vision/json-api-parser';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER } from '../../../../const';
import { Csm, } from '../../../../types';
import { DzDialog, useApiClient, useGlobalStyles } from '../../../shared';
import { useStyles } from './dz-edit-membership-info-dialog.styles';
import DzRichTextEditor from '../../../shared/dz-rich-text-editor';
import { replaceNewLineWithParagraph } from '../../../../utils/replaceNewLineWithParagraph';
import { S3Client } from '../../../../utils';
import { TosStatus } from '../../../../const';
import { useFormStyles } from '../../../shared';
export const DzEditMembershipInfoDialog = ({ isOpen, onClose, address, actions, thunks, dispatch, zendesk, }) => {
    const globalClasses = useGlobalStyles();
    const classes = Object.assign(Object.assign({}, useFormStyles()), useStyles());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialMembershipInfo = useMemo(() => ({
        clientSelectedMembership: address.clientSelectedMembership,
        cppExpDate: address.cppExpDate || null,
        membershipSalesNotes: address.membershipSalesNotes || '',
        tosAccepted: address.tosAccepted || 'No',
    }), [address]);
    const [membershipInfo, setMembershipInfo] = useState(initialMembershipInfo);
    const [dateValue, setDateValue] = useState(initialMembershipInfo.cppExpDate);
    const [products, setProducts] = useState([]);
    useEffect(() => {
        setMembershipInfo(initialMembershipInfo);
        setDateValue(initialMembershipInfo.cppExpDate);
        (async () => {
            try {
                const params = {
                    include: ['productName'],
                    filter: {
                        ovbid: address.ovbid,
                    },
                };
                const { data: response } = await apiClient.getProducts(params);
                // @ts-expect-error json-api-parser types require further refinement.
                const { entities } = readAsList(response);
                const { Product, ProductName } = entities;
                const visibleMemberships = Product.filter((product) => product.productOffered).map((product) => {
                    var _a, _b, _c;
                    const productNameId = (_a = product.relationships.productName) === null || _a === void 0 ? void 0 : _a[0].id;
                    const productName = (_c = (_b = ProductName.find((productName) => productName.id === productNameId)) === null || _b === void 0 ? void 0 : _b.productName) !== null && _c !== void 0 ? _c : '';
                    return Object.assign(Object.assign({}, product), { productName, productId: productNameId });
                });
                setProducts(visibleMemberships);
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, [address]);
    const handleOnChangeDesired = useCallback((_, value) => {
        setMembershipInfo(Object.assign(Object.assign({}, membershipInfo), { clientSelectedMembership: value }));
    }, [membershipInfo]);
    const handleOnChangeTos = useCallback((_, checked) => {
        setMembershipInfo(Object.assign(Object.assign({}, membershipInfo), { tosAccepted: checked ? TosStatus.YES : TosStatus.NO }));
    }, [membershipInfo]);
    const handleOnChangeMembershipSalesNotes = useCallback(({ editor }) => {
        const value = editor.getHTML();
        setMembershipInfo(Object.assign(Object.assign({}, membershipInfo), { membershipSalesNotes: value }));
    }, [membershipInfo]);
    const changes = useMemo(() => getChanges(initialMembershipInfo, membershipInfo), [initialMembershipInfo, membershipInfo]);
    const handleSubmit = () => {
        setIsSubmitting(true);
        if (!address) {
            return;
        }
        const updates = Object.assign({}, changes);
        if (changes.tosAccepted === TosStatus.YES) {
            updates.tosSubmissionDate = new Date().toISOString().split('T')[0];
        }
        dispatch(thunks.updateAddress({
            ovaid: address.ovaid,
            changes: updates,
        }))
            .unwrap()
            .then(() => {
            dispatch(actions.addressUpdated(Object.assign(Object.assign({}, address), changes)));
            dispatch(actions.updateSnackbar({
                text: 'Membership info was changed',
                type: 'success',
            }));
            setIsSubmitting(false);
            onClose();
        });
    };
    const productsList = [Csm.Undecided, Csm['Declined Membership']].concat(products
        .filter((el) => el.productOffered)
        .map((el) => el.productName.replace('+', ' Plus'))
        .sort());
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    const content = useMemo(() => {
        return (_jsxs(Stack, Object.assign({ spacing: 2 }, { children: [_jsxs(Box, Object.assign({ display: "flex" }, { children: [!!productsList.length && (_jsx(FormControl, Object.assign({ className: csn(globalClasses.gcFieldMargin, classes.fieldWithInfo) }, { children: _jsx(Autocomplete, { autoHighlight: true, openOnFocus: true, defaultValue: membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.clientSelectedMembership, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Desired Membership", margin: "none" }))), options: productsList, getOptionLabel: (option) => option, isOptionEqualToValue: (option) => option === (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.clientSelectedMembership), onChange: handleOnChangeDesired }) }))), _jsx(Box, Object.assign({ className: classes.infoIconWrapper }, { children: _jsx(Tooltip, Object.assign({ title: "This is the membership the client committed to in the proposal process.", placement: "left", arrow: true }, { children: _jsx(InfoIcon, { type: "outlined" }) })) }))] })), _jsx(FormControlLabel, { className: csn(classes.switchFormLabel, [
                        classes.zendeskSwitchFormLabel,
                        zendesk,
                    ]), control: _jsxs(Box, Object.assign({ className: classes.switchWrap }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Accepted" })), _jsx(Switch, { id: "tosAccepted", classes: {
                                    root: classes.rotatedSwitch,
                                }, checked: membershipInfo.tosAccepted.toLowerCase() ===
                                    TosStatus.YES.toLowerCase(), onChange: handleOnChangeTos, color: "primary" }), _jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Not Accepted" }))] })), label: _jsx(Box, Object.assign({ className: classes.toggleLabel }, { children: "Terms of Service" })), labelPlacement: "start" }), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ className: csn(classes.title, [classes.titleZen, zendesk]) }, { children: "Membership Sales Notes" })), _jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(membershipInfo.membershipSalesNotes), onUpdate: handleOnChangeMembershipSalesNotes, placeholder: MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, s3Client: s3Client })] })] })));
    }, [isSubmitting, membershipInfo, dateValue, apiClient, productsList]);
    return (_jsx(DzDialog, { isOpen: isOpen, caption: "Edit Membership Info", okButtonText: "Submit", okButtonDisabled: isSubmitting || !Object.keys(changes).length, isSubmittingLoader: isSubmitting, content: content, onClose: onClose, onOk: handleSubmit, zendesk: zendesk }));
};
