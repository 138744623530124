import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Csm, ServiceLevel, SubscriptionStatus, } from '../types';
dayjs.extend(timezone);
export function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}
export function pickColor(str) {
    return `hsl(${hashCode(str) % 360}, 25%, 50%)`;
}
export function getAbbr(value) {
    return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
}
export function getServiceLevelStyle(theme, serviceLevel) {
    switch (serviceLevel) {
        case ServiceLevel.Priority:
        case ServiceLevel['CPP Priority']:
            return theme.dz.styles.membershipColorsOrg.priority;
        case ServiceLevel['Essentials Plus']:
        case ServiceLevel['CPP Essentials Plus']:
            return theme.dz.styles.membershipColorsOrg.essentialsPlus;
        case ServiceLevel.Inactive:
        case ServiceLevel['Non-Member']:
            return theme.dz.styles.membershipColorsOrg.nonMember;
        case ServiceLevel.Proactive:
        case ServiceLevel['CPP Proactive']:
            return theme.dz.styles.membershipColorsOrg.proactive;
        case ServiceLevel.Signature:
        case ServiceLevel['CPP Signature']:
            return theme.dz.styles.membershipColorsOrg.signature;
        default:
            return theme.dz.styles.membershipColorsOrg.nonMember;
    }
}
export function getDesiredMembershipStyle(theme, desiredMembership) {
    switch (desiredMembership) {
        case Csm['Essentials Plus']:
            return theme.dz.colors.essentialsPlus;
        case Csm.Priority:
            return theme.dz.colors.priority;
        case Csm.Proactive:
            return theme.dz.colors.proactive;
        case Csm.Signature:
            return theme.dz.colors.signature;
        case Csm['Undecided']:
        case Csm['Declined Membership']:
            return theme.dz.colors.declinedOrUndecided;
        default:
            return theme.dz.colors.declinedOrUndecided;
    }
}
export function getSubscriptionStatusStyle(theme, subscriptionStatus) {
    switch (subscriptionStatus) {
        case SubscriptionStatus.Active:
            return theme.dz.colors.success;
        case SubscriptionStatus['Past Due']:
        case SubscriptionStatus.Unpaid:
        case SubscriptionStatus.Canceled:
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus['Incomplete Expired']:
            return theme.dz.colors.error;
        case SubscriptionStatus.Trialing:
            return theme.dz.colors.signature;
        default:
            return theme.dz.colors.declinedOrUndecided;
    }
}
export const getSubscriptionStatusTooltipText = (status) => {
    const statusesTexting = {
        [SubscriptionStatus.Active]: '',
        [SubscriptionStatus['Past Due']]: 'The most recent payment attempt failed. Please use the payment portal to update the client’s CC information.',
        [SubscriptionStatus.Unpaid]: 'We have not been able to process a payment for over 30 days. Please use the payment portal to update the client’s CC information.',
        [SubscriptionStatus.Canceled]: 'Membership is no longer active. Please use the Client Activation Flow to activate a new membership if desired.',
        [SubscriptionStatus.Incomplete]: 'The initial activation attempt failed. Please use the payment portal to update the client’s CC information.',
        [SubscriptionStatus['Incomplete Expired']]: 'The initial activation attempt failed. Please use the payment portal to update the client’s CC information.',
        [SubscriptionStatus.Trialing]: 'The subscription is still in trial mode and will flip to Active upon the trial ending as long as we have a payment method on file.',
    };
    return (statusesTexting === null || statusesTexting === void 0 ? void 0 : statusesTexting[status]) || '';
};
export function isResidential(ovbid, brands) {
    return getBrandName(ovbid, brands).toLowerCase().includes('residential');
}
export function getBrandName(ovbid, brands) {
    var _a;
    return ((ovbid &&
        brands.length &&
        ((_a = brands.find((brand) => brand.ovbid === ovbid)) === null || _a === void 0 ? void 0 : _a.name)) ||
        '');
}
export function getAddressTitle(address) {
    if (!address) {
        return '';
    }
    const businessName = address.businessName
        ? `(${address.businessName})`
        : '';
    return `${address.city || ''} -
      ${address.address1 || ''} ${address.address2 || ''} ${businessName}`;
}
export function getAddressCopyTitle(address) {
    if (!address) {
        return '';
    }
    const { city, state, zip } = address;
    const onlyAddress = `${address.address1}${address.address2
        ? ` ${address.address2}${city || state || zip ? ', ' : ''}`
        : city || state || zip
            ? ', '
            : ''}`;
    return `${onlyAddress}${city ? `${city}${state || zip ? ', ' : ''}` : ''}${state ? `${state}${zip ? ', ' : ''}` : ''}${zip || ''}`;
}
//https://www.notion.so/onevision/Account-for-LiTS-in-the-side-panel-ProVision-App-fbb591ec2a094786a53b527706a3486a
export function getServiceLevelValue({ partner, address, timeZone, }) {
    if (!partner || !address) {
        return '';
    }
    if (!partner.litsDate) {
        return address.clientActivatedMembership;
    }
    const litsDate = dayjs.tz(partner.litsDate, timeZone);
    const nowInTimezone = dayjs().tz(timeZone);
    const isActivated = litsDate.isBefore(nowInTimezone) &&
        !litsDate.isSame(nowInTimezone, 'day');
    const isNotActivatedButValid = (litsDate.isAfter(nowInTimezone) ||
        litsDate.isSame(nowInTimezone, 'day')) &&
        address.clientActivatedMembership !== ServiceLevel['Non-Member'] &&
        address.clientActivatedMembership !== ServiceLevel['Inactive'];
    if (isActivated || isNotActivatedButValid) {
        return address.clientActivatedMembership;
    }
    return 'Pre-LitS Priority';
}
const digitsInValidPhoneNumber = 10;
export function tryToFormatPhoneNumber(phone) {
    if ((phone === null || phone === void 0 ? void 0 : phone.length) !== digitsInValidPhoneNumber) {
        return phone;
    }
    const result = `+1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    return result;
}
export const formatDateToLongFormat = (date) => new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
});
export const formatDateToLocalString = (params) => {
    const date = params.date ? new Date(params.date) : new Date();
    return date.toLocaleString('en-US', params.options);
};
export const removeRedundantSpaces = (text) => text.replace(/\s+/g, ' ').trim();
export const addZeroToDate = (time) => time < 10 ? `0${time}` : time;
export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);
export const arraysAreEqual = (arr1, arr2) => arr1.toString() === arr2.toString();
var ordinalSuffixes;
(function (ordinalSuffixes) {
    ordinalSuffixes["one"] = "st";
    ordinalSuffixes["two"] = "nd";
    ordinalSuffixes["few"] = "rd";
    ordinalSuffixes["other"] = "th";
})(ordinalSuffixes || (ordinalSuffixes = {}));
//get ordinals suffix, example 1 => 1st etc.
export const getNumberSuffix = (number) => {
    const category = new Intl.PluralRules('en', { type: 'ordinal' }).select(number);
    return ordinalSuffixes[category];
};
export const isValidHttpUrl = (urlString) => {
    let url;
    try {
        url = new URL(urlString);
    }
    catch (_a) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};
export function isTrue(value) {
    return (value === null || value === void 0 ? void 0 : value.toLowerCase()) === 'true';
}
export const textToCamelCase = (str) => str
    .toLowerCase()
    .split(/[^a-zA-Z]+/)
    .map((word, index) => index === 0 ? word : capitalizeFirstLetter(word))
    .join('');
export const compareArrays = (oldArray, newArray) => {
    const removed = [];
    const kept = [];
    const added = [];
    // Check for removed and kept elements
    oldArray.forEach((element) => {
        if (newArray.includes(element)) {
            kept.push(element);
        }
        else {
            removed.push(element);
        }
    });
    // Check for added elements
    newArray.forEach((element) => {
        if (!oldArray.includes(element)) {
            added.push(element);
        }
    });
    return { removed, kept, added };
};
//convert from +1 (111) 111-1111 to +11111111111
export const setPhoneNumberToE164 = (phone) => `+${phone.replace(/\D/g, '')}`;
// TODO: Refactor this function and DzPhoneInput ASAP.
//convert from +11111111111 to +1 (111) 111-1111
export const setToUIFormat = (phone) => {
    const countryCode = phone.slice(0, 2);
    const remainingDigits = phone.slice(2);
    return `${countryCode} (${remainingDigits.slice(0, 3)}) ${remainingDigits.slice(3, 6)}-${remainingDigits.slice(6)}`;
};
