import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState, useEffect, } from 'react';
import { TextField, FormControl, FormHelperText, Autocomplete, Typography, Box, } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { add, format } from '@one-vision/date-utils';
import { logRender } from '@one-vision/utils';
import { DzDialog } from '../../';
import { addZeroToDate } from '../../../utils';
import { readAsList } from '@one-vision/json-api-parser';
import { withModalManager, useApiClient, DzDateTimePicker, } from '../../shared';
import { DzChip } from '../../shared/dz-chip';
import { ISO_DATE_FORMAT, MAX_CPP_EXP_DAYS } from '../../../const';
import { useStyles } from './dz-cpp-exp-dialog.styles';
const CUSTOM_LABEL = 'Set Custom Expiration Date';
export const DATE_LENGTH = 10;
export const TODAY_DATE = new Date();
export const TODAY_DATE_STRING = format(TODAY_DATE, ISO_DATE_FORMAT);
export const TOMORROW_DATE = add({
    date: TODAY_DATE,
    value: 1,
    unit: 'day',
});
export const MAX_DATE = add({
    date: TODAY_DATE,
    value: MAX_CPP_EXP_DAYS,
    unit: 'day',
});
const cppLengthList = [
    {
        label: '30 Days',
        value: add({ date: TODAY_DATE, value: 30, unit: 'day' }),
    },
    {
        label: '60 Days',
        value: add({ date: TODAY_DATE, value: 60, unit: 'day' }),
    },
    {
        label: '90 Days',
        value: add({ date: TODAY_DATE, value: 90, unit: 'day' }),
    },
    {
        label: '180 Days',
        value: add({ date: TODAY_DATE, value: 180, unit: 'day' }),
    },
    { label: CUSTOM_LABEL, value: TOMORROW_DATE },
];
const DzCppExpDialog = ({ address, dispatch, actions, isOpen, close, clear }) => {
    logRender(DzCppExpDialog);
    const classes = useStyles();
    const api = useApiClient();
    const [selectedChip, setSelectedChip] = useState(cppLengthList[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const validationSchema = yup.object({
        serviceLevel: yup.string().nullable().required('Required'),
        cppExpDate: yup.date().nullable().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            serviceLevel: null,
            cppExpDate: selectedChip.value,
        },
        validationSchema,
        onSubmit: async (formikRes) => {
            if (!formikRes.serviceLevel) {
                formik.setFieldError('serviceLevel', 'Required');
                return;
            }
            setIsLoading(true);
            const date = `${formikRes.cppExpDate.getFullYear()}-${addZeroToDate(formikRes.cppExpDate.getMonth() + 1)}-${addZeroToDate(formikRes.cppExpDate.getDate())}`;
            const { data: [addressUpdated], } = await api.updateAddress(address.ovaid, {
                clientActivatedMembership: formikRes.serviceLevel,
                cppExpDate: date,
            });
            dispatch(actions.addressIsUpdating({
                clientActivatedMembership: addressUpdated.clientActivatedMembership,
                cppExpDate: addressUpdated.cppExpDate,
            }));
            setIsLoading(false);
            handleClose();
        },
    });
    const apiClient = useApiClient();
    useEffect(() => {
        (async () => {
            try {
                const params = {
                    include: ['productName'],
                    filter: {
                        ovbid: address.ovbid,
                    },
                };
                const { data: response } = await apiClient.getProducts(params);
                // @ts-expect-error json-api-parser types require further refinement.
                const { entities } = readAsList(response);
                const { Product, ProductName } = entities;
                const visibleMemberships = Product.filter((product) => product.productOffered).map((product) => {
                    var _a, _b, _c;
                    const productNameId = (_a = product.relationships.productName) === null || _a === void 0 ? void 0 : _a[0].id;
                    const productName = (_c = (_b = ProductName.find((productName) => productName.id === productNameId)) === null || _b === void 0 ? void 0 : _b.productName) !== null && _c !== void 0 ? _c : '';
                    return Object.assign(Object.assign({}, product), { productName, productId: productNameId });
                });
                setProducts(visibleMemberships);
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, []);
    const onServiceLevelChange = useCallback((_, newValue) => {
        formik.setFieldValue('serviceLevel', newValue);
    }, [formik]);
    const onDateChange = useCallback((value, inputValue) => {
        let checkedDate = value;
        const isValid = value && (inputValue === null || inputValue === void 0 ? void 0 : inputValue.length) === DATE_LENGTH;
        if (isValid && value < TOMORROW_DATE) {
            checkedDate = TOMORROW_DATE;
        }
        if (isValid && value > MAX_DATE) {
            checkedDate = MAX_DATE;
        }
        setSelectedChip({ label: CUSTOM_LABEL, value: checkedDate });
        formik.setFieldValue('cppExpDate', checkedDate);
    }, [setSelectedChip, formik]);
    const onChipChange = useCallback((item) => {
        setSelectedChip(item);
        formik.setFieldValue('cppExpDate', item.value);
    }, [setSelectedChip, formik]);
    const serviceLevelError = Boolean(formik.touched['serviceLevel'] && formik.errors['serviceLevel']);
    const datePickerError = formik.touched['cppExpDate'] &&
        formik.errors['cppExpDate'];
    const productsList = products
        .filter((el) => el.productOffered)
        .map((el) => `CPP ${el.productName.replace('+', ' Plus')}`).sort();
    const content = useMemo(() => (_jsxs(_Fragment, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: serviceLevelError, className: classes.marginBottom }, { children: [_jsx(Typography, Object.assign({ className: classes.title }, { children: "Details" })), _jsx(Autocomplete, { id: "serviceLevel", value: formik.values['serviceLevel'], autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: 'Service Level', margin: "none", error: serviceLevelError }))), options: productsList, isOptionEqualToValue: (option) => option === formik.values['serviceLevel'], onChange: onServiceLevelChange, onBlur: formik.handleBlur }), serviceLevelError && (_jsx(FormHelperText, { children: formik.errors['serviceLevel'] }))] })), _jsx(Typography, Object.assign({ className: classes.title }, { children: "Length of Complimentary Period" })), _jsx(Box, Object.assign({ className: classes.chipContainer }, { children: cppLengthList.map((el) => (_jsx(DzChip, { label: el.label, checked: el.label === selectedChip.label, onClick: () => onChipChange(el), className: classes.chip }, el.label))) })), _jsx(DzDateTimePicker, { disablePast: true, fullWidth: true, inputFormat: "MM/dd/yyyy", mask: "__/__/____", value: selectedChip === null || selectedChip === void 0 ? void 0 : selectedChip.value, name: "cppExpDate", onChange: onDateChange, maxDate: MAX_DATE.toISOString(), disabled: selectedChip.label !== CUSTOM_LABEL, minDate: TOMORROW_DATE.toISOString(), label: "CPP Expiration Date", error: datePickerError })] })), [selectedChip, formik]);
    const handleClose = useCallback(() => {
        close();
    }, [close]);
    return (_jsx(DzDialog, { isOpen: isOpen, caption: "Add Complimentary Period", content: content, okButtonText: "Submit", clear: clear, onClose: handleClose, onOk: formik.submitForm, okButtonDisabled: isLoading, isSubmittingLoader: isLoading, cancelButtonText: "Cancel" }));
};
export default withModalManager()(DzCppExpDialog);
