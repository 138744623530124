import { API } from 'core/api';
import { useState, useCallback, useEffect } from 'react';
import { Group } from './dz-users.users-view';

export const useUserGroups = () => {
  const [loading, setLoading] = useState(true);
  const [userGroupsList, setUserGroupsList] = useState<Group[]>([]);

  const getUserGroups = useCallback(async () => {
    setLoading(true);
    const {
      data: { data },
    } = await API.getUserGroups({ page: { limit: 200 } });
    setUserGroupsList(
      data.map((el) => ({
        id: el.id as string,
        name: el.attributes.name,
        description: el.attributes.description,
        isPublic: el.attributes.isPublic,
      })),
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    getUserGroups();
  }, [getUserGroups]);

  return { loadingGroups: loading, userGroupsList };
};
