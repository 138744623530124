import { Popover, Skeleton, TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { addZeroTime } from '@one-vision/date-utils';
import { v4 as uuid } from 'uuid';
import {
  DzAddressSidebar,
  DzLayoutPage,
  DzOwnerSelectionPopup,
  DzPageHeader,
} from 'components/shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProjectsPage } from './useProjectsPage';
import { DUMMY_ID } from 'shared/constants';
import { useDzSelector } from 'core/redux';
import { DzAsyncDispatch, Projects } from 'shared-ui';
import { useDispatch } from 'react-redux';
import { exportToCsv } from 'shared/utils/exportToCsv';

const dummies = new Array(3).fill(0).map((_, i) => ({
  ovprjid: `ovprjid-${DUMMY_ID}-${i}`,
  fakeid: `fakeid-${uuid()}`,
  ovaid: null,
  ownerId: null,
}));

const DzProjectsPage: React.FC = () => {
  const {
    searchValue,
    views,
    activeView,
    favorites,
    handleViewChange,
    handleAddFavorite,
    handleRemoveFavorite,
    selectNextView,
    selectPrevView,
    activeViewPreSearchList,
    onSearch,
    projectsColumns,
    rows,
    handleBodyScroll,
    isReady,
    isLoadingMoreRows,
    ownerPopupInfo,
    handleCloseOwnerSelection,
    handleUpdateOwner,
    handleAddProject,
    datePopupInfo,
    closeDatePopup,
    handleDateChange,
    setActiveViewPreSearchListItem,
    openSidebar,
    closeSidebar,
    checkIsAnyDialogActive,
    fetchData,
  } = useProjectsPage();

  const [isExporting, setExportingStatus] = useState(false);

  const dispatch = useDispatch<DzAsyncDispatch>();
  const list = useDzSelector((state) =>
    Projects.selectors.selectList(state, activeView),
  );

  const displayedRows = useMemo(() => {
    if (!isReady && rows.length === 0) {
      return dummies;
    }

    return isLoadingMoreRows ? [...rows, ...dummies] : rows;
  }, [isReady, rows, isLoadingMoreRows]);

  const defaultColumnDef = useMemo(
    () => ({
      sortable: false,
    }),
    [],
  );

  const handleExport = useCallback(() => {
    const headerRow = ['ID'].concat(
      projectsColumns.map((column) => column.headerName || ''),
    );

    const dataRows = rows.map((row) => {
      return [row.ovprjid].concat(
        projectsColumns.map<string>(
          ({ toStringRepresentation, cellRenderer }) => {
            return toStringRepresentation
              ? toStringRepresentation({ data: row })
              : (cellRenderer({ data: row }) as string);
          },
        ),
      );
    });

    exportToCsv(`${activeView}_projects`, [headerRow].concat(dataRows), {
      withTimestamp: true,
    });
  }, [projectsColumns, rows, activeView]);

  const handleExportClick = useCallback(async () => {
    if (list.page === list.lastPage && list.isReady) {
      handleExport();
      return;
    }
    dispatch(
      Projects.actions.updateList(
        {
          isReady: false,
        },
        activeView,
      ),
    );
    setExportingStatus(true);
    fetchData({
      page: 1,
      perPage: 200,
    });
  }, [
    dispatch,
    setExportingStatus,
    fetchData,
    activeView,
    handleExport,
    list,
  ]);

  useEffect(() => {
    if (!isExporting) {
      return;
    }
    if (list.page !== list.lastPage && list.isReady) {
      fetchData();
    }

    if (list.page === list.lastPage && list.isReady) {
      setExportingStatus(false);
      handleExport();
    }
  }, [isExporting, setExportingStatus, list, fetchData, handleExport]);

  return (
    <>
      <Skeleton height={0} width={0} />
      <DzLayoutPage
        isLoading={false}
        preparedColumns={projectsColumns}
        defaultColumnDef={defaultColumnDef}
        preparedRows={displayedRows}
        uniqueFieldName="ovprjid"
        gridName="projects"
        activeView={activeView}
        selectNextView={selectNextView}
        selectPrevView={selectPrevView}
        layoutHeader={
          <DzPageHeader
            searchValue={searchValue}
            views={views}
            activeView={activeView}
            handleViewChange={handleViewChange}
            onSearch={onSearch}
            favorites={favorites}
            handleAddFavorite={handleAddFavorite}
            handleRemoveFavorite={handleRemoveFavorite}
            onAddButtonClick={handleAddProject}
            buttonName="Project"
            useSelectSidebarPanel={() => ({
              isOpen: false,
            })}
            preSearchList={activeViewPreSearchList}
            setItemToPreSearchList={setActiveViewPreSearchListItem}
            handleExportClick={handleExportClick}
            exportButtonDisabled={!list.isReady || isExporting}
            isExporting={isExporting}
          />
        }
        layoutSidebar={
          <DzAddressSidebar
            checkIsAnyDialogActive={checkIsAnyDialogActive}
          />
        }
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        onBodyScroll={handleBodyScroll}
        ownerPopup={
          ownerPopupInfo.row ? (
            <DzOwnerSelectionPopup
              ownerType={'project'}
              anchor={ownerPopupInfo.anchor || undefined}
              id={ownerPopupInfo.row.ovprjid}
              ownerId={ownerPopupInfo.row.owner?.ownerId || ''}
              addressId={ownerPopupInfo.row.ovaid}
              projectId={ownerPopupInfo.row.ovprjid}
              onGetOnUpdateAction={handleUpdateOwner}
              onClose={handleCloseOwnerSelection}
            />
          ) : undefined
        }
      />
      <Popover
        open={Boolean(datePopupInfo.anchor)}
        anchorEl={datePopupInfo.anchor}
        onClose={closeDatePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StaticDatePicker<Date>
          displayStaticWrapperAs="desktop"
          orientation="landscape"
          openTo="day"
          value={
            datePopupInfo.project?.[datePopupInfo.dateName] &&
            addZeroTime(datePopupInfo.project?.[datePopupInfo.dateName])
          }
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Popover>
    </>
  );
};

export default DzProjectsPage;
