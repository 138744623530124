var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @TODO
import { useContext } from 'react';
import ModalContext from './dz-modal-context';
// eslint-disable-next-line @typescript-eslint/ban-types
const withModalManager = function () {
    return function (WrappedComponent) {
        return Object.assign(function WithModalManager(_a) {
            var { id } = _a, props = __rest(_a, ["id"]);
            const { propsDictionary } = useContext(ModalContext);
            const modalProps = propsDictionary[id];
            if (modalProps) {
                const { resolve: _ } = modalProps, injectedProps = __rest(modalProps, ["resolve"]);
                return (
                // eslint-disable-next-line
                // @ts-expect-error expected
                _jsx(WrappedComponent, Object.assign({ id: id }, props, injectedProps)));
            }
            return null;
        }, {
            displayName: `WithModalManager(${WrappedComponent.displayName ||
                WrappedComponent.name ||
                'Component'})`,
            WrappedComponent,
        });
    };
};
export default withModalManager;
