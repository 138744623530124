import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Menu, MenuItem, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { homePlus } from 'assets';
import {
  DzDisassociateUserView as DzDisassociateUserDialog,
  DzAddress,
  DzClient,
  useModalManager,
  ModalIDs,
  DzZendeskIcon,
  useTimer,
  writeToClipboard,
  DzContentCopyIcon,
  DzAsyncDispatch,
  DzSearchInput,
} from 'shared-ui';
import {
  actions,
  thunks,
  useDzSelector,
  useSelectUserSidebar,
  useSelectClientLabels,
} from 'core/redux';
import { ClientToAddress } from 'types';
import { useStyles } from './dz-users-sidebar-content.styles';
import { selectOwners } from 'core/redux/owners.redux';
import { usePartner } from 'shared/hooks';
import { DzUsersSidebarContacts } from '../dz-users-sidebar-contacts';
import { DzUsersSidebarOrganizations } from '../dz-users-sidebar-organizations';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import { Skeleton } from '@mui/material';
import { Routes } from 'core/routes';
import { RequestParams } from 'types';
import { DzAddClientToAddressDialogProps } from '../add-client-to-address-dialog';

interface Props {
  isLoading: boolean;
}

const EDIT_ADDRESS = 'Edit Organization';
const OPEN_ZENDESK = 'Open in Zendesk';
const COPY_ADDRESS = 'Copy Address ID';

export const DzUsersSidebarContent: React.FC<Props> = ({ isLoading }) => {
  const classes = useStyles();

  const dispatch = useDispatch<DzAsyncDispatch>();

  const brands = useDzSelector((state) => state.brands);
  const partners = useDzSelector((state) => state.settings.partners);
  const { client, addresses } = useSelectUserSidebar();
  const clientLabels = useSelectClientLabels();
  const owners = useDzSelector(selectOwners);

  const [currentAddress, setCurrentAddress] = useState<DzAddress | null>(
    null,
  );

  const [isAddOrgMenuOpen, setIsAddOrgMenuOpen] = useState(false);
  const [isModifyOrgMenuOpen, setIsModifyOrgMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const toggleAddOrgMenu = useCallback(() => {
    setIsAddOrgMenuOpen(!isAddOrgMenuOpen);
  }, [isAddOrgMenuOpen]);
  const toggleModifyOrgMenu = useCallback(() => {
    setIsModifyOrgMenuOpen(!isModifyOrgMenuOpen);
  }, [isModifyOrgMenuOpen]);

  const refMenu = useRef(null);
  const partner = usePartner();

  const [disassociateAddressId, setDisassociateAddressId] = useState<
    DzAddress['ovaid'] | null
  >(null);
  const [clientToDisassociate, setClientToDisassociate] = useState<
    DzClient['ovcid'] | null
  >(null);
  const memoizedClients = useMemo(
    () => (client ? [client] : []),
    [client],
  );

  const zendeskUrl = partners.find((partner) =>
    currentAddress
      ? partner.partnerId === Number(currentAddress?.ovpid)
      : false,
  )?.zendeskUrl;

  const [isTimerRunning, startTimer] = useTimer();

  const handleRedirectZen = useCallback(() => {
    if (zendeskUrl && currentAddress) {
      window.open(
        `${zendeskUrl}/organizations/${currentAddress.zdOrgId}`,
        '_blank',
      );
    }
  }, [zendeskUrl, currentAddress]);

  const copyAddress = useCallback(async () => {
    if (!currentAddress) {
      return;
    }
    await writeToClipboard(currentAddress.ovaid, 'text/plain');
    startTimer();
  }, [startTimer, currentAddress]);

  const navigate = useNavigate();

  const menuList = [
    {
      name: EDIT_ADDRESS,
      icon: (
        <AssignmentIcon
          color="primary"
          classes={{ colorPrimary: classes.editIcon }}
          fontSize="small"
        />
      ),
      action: useCallback(() => {
        if (currentAddress) {
          console.warn(
            'Problem with type: DzAddressPrefixed instead DzAddress',
          );
          dispatch(
            actions.openUpdateAddressDialog(
              currentAddress as unknown as DzAddress,
            ),
          );
        }
        toggleModifyOrgMenu();
      }, [currentAddress, dispatch, toggleModifyOrgMenu]),
    },
    {
      name: 'Disassociate from Organization',
      icon: <DeleteForeverIcon color="disabled" fontSize="small" />,
      action: useCallback(() => {
        setDisassociateAddressId(currentAddress?.ovaid || null);
        setClientToDisassociate(client?.ovcid || null);
        toggleModifyOrgMenu();
      }, [client, currentAddress, toggleModifyOrgMenu]),
    },
    {
      name: OPEN_ZENDESK,
      icon: (
        <DzZendeskIcon
          viewBox="0 0 24 24"
          classes={{ root: classes.zendeskIcon }}
        />
      ),
      action: handleRedirectZen,
    },
    {
      name: COPY_ADDRESS,
      icon: isTimerRunning ? (
        <CheckIcon fontSize="small" />
      ) : (
        <DzContentCopyIcon fontSize="small" />
      ),
      action: copyAddress,
    },
  ].filter((el) => {
    if (el.name === OPEN_ZENDESK && !currentAddress?.zdOrgId) {
      return false;
    }
    return true;
  });

  const closeDisassociateDialog = useCallback(() => {
    setDisassociateAddressId(null);
    setClientToDisassociate(null);
  }, [setDisassociateAddressId, setClientToDisassociate]);

  const { openModal } = useModalManager();

  const addClientToAddressDialogHandler = useCallback(async () => {
    toggleAddOrgMenu();

    if (!client?.ovcid) {
      return;
    }

    const result = await openModal<
      DzAddClientToAddressDialogProps,
      ClientToAddress
    >(ModalIDs.addClientToAddress, {
      clientData: {
        ovcid: client.ovcid,
        ovaid: '',
        email: client.emails.length ? client?.emails[0]?.email : '',
      },
      addressesToFilter: addresses,
    });

    if (!result) {
      return;
    }

    dispatch(
      thunks.fetchAddress({
        'client-id': result.ovcid,
      }),
    )
      .unwrap()
      .then((addresses) => {
        if (addresses) {
          dispatch(
            actions.updateUserSideBarState({
              addresses: uniqBy(
                // TODO: This filter can be removed once duplicated address entities will not be resolved anymore
                // For that we need to fix clientToAddress or topStageProject joins in adress.service in ov-api
                addresses,
                (address) => address.clientToAddress_ovaid,
              ),
            }),
          );
        }
      });
  }, [dispatch, client, toggleAddOrgMenu, openModal, addresses]);

  const addressesList = useMemo(
    () =>
      addresses.filter((address) => {
        if (!searchText) {
          return true;
        }
        const searchTextLowerCase = searchText.toLowerCase();
        return Boolean(
          Object.values(address).find((value: string) =>
            value?.toString().toLowerCase().includes(searchTextLowerCase),
          ),
        );
      }),
    [addresses, searchText],
  );

  const searchHandler = useCallback(
    (text: string) => {
      setSearchText(text);
    },
    [setSearchText],
  );

  const redirectToOrg = useCallback(
    (id: string) => {
      dispatch(
        actions.updateOrganizationDialogState({
          ovaid: id,
        }),
      );

      navigate(`${Routes.Organizations}?${RequestParams.Selected}=${id}`);
    },
    [dispatch, navigate],
  );

  return (
    <div className={classes.root}>
      <DzDisassociateUserDialog
        clientID={clientToDisassociate}
        ovaid={disassociateAddressId || ''}
        closeDialog={closeDisassociateDialog}
        actions={actions}
        thunks={thunks}
        dispatch={dispatch}
      />

      <DzUsersSidebarContacts client={client} isLoading={isLoading} />

      {isLoading ? (
        <>
          <Box className={classes.placeholder}>
            <Skeleton
              animation="wave"
              variant="text"
              width={175}
              height={26}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width={100}
              height={26}
            />
          </Box>
          <Box className={classes.titleSkeleton}>
            <Skeleton
              animation="wave"
              variant="text"
              width={200}
              height={26}
            />
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.addOrgHeader}>
            <Box width="50%">
              <DzSearchInput
                placeholder="Filter results"
                onTextChange={searchHandler}
              />
            </Box>
            <Button
              color="primary"
              className={classes.menu}
              onFocus={(event) => event.stopPropagation()}
              onClick={toggleAddOrgMenu}
              ref={refMenu}
            >
              Menu
            </Button>
            <Menu
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isAddOrgMenuOpen}
              anchorEl={refMenu.current}
              onClose={toggleAddOrgMenu}
            >
              <MenuItem onClick={addClientToAddressDialogHandler}>
                <img
                  className={classes.menuImg}
                  src={homePlus}
                  loading="lazy"
                />
                <span className={classes.menuItem}>
                  Add to another organization
                </span>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            title="Associated organizations"
            variant="subtitle1"
            className={classes.contentTitle}
          >
            Associated organizations
          </Typography>
        </>
      )}
      <DzUsersSidebarOrganizations
        clients={memoizedClients}
        addresses={addressesList}
        isLoading={isLoading}
        setCurrentAddress={setCurrentAddress}
        brands={brands}
        owners={owners}
        menuList={menuList}
        isModifyOrgMenuOpen={isModifyOrgMenuOpen}
        currentAddress={currentAddress}
        toggleModifyOrgMenu={toggleModifyOrgMenu}
        partner={partner}
        clientLabels={clientLabels}
        onOrgCardNameClick={redirectToOrg}
      />
    </div>
  );
};
