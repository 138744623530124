import { useEffect, useRef, useState } from 'react';

interface Output {
  isMouseBeingMoved: boolean;
}

const MOUSE_MOVE_EVENT = 'mousemove';

export const useDetectMouseMove = (): Output => {
  const timeout = useRef<null | NodeJS.Timeout>(null);
  const [isMouseBeingMoved, setIsMouseBeingMoved] = useState(false);

  useEffect(() => {
    const startInteractionTimer = () => {
      if (isMouseBeingMoved) {
        setIsMouseBeingMoved(false);
      }
    };

    const mouseMoveListener = function () {
      if (!isMouseBeingMoved) {
        setIsMouseBeingMoved(true);
      }

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(startInteractionTimer, 200);
    };

    document.addEventListener(MOUSE_MOVE_EVENT, mouseMoveListener, false);

    return () => {
      document.removeEventListener(
        MOUSE_MOVE_EVENT,
        mouseMoveListener,
        false,
      );
    };
  }, [isMouseBeingMoved, setIsMouseBeingMoved]);

  return { isMouseBeingMoved };
};
